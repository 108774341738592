import { Router } from '@reach/router';
import React, { FC } from 'react';

import { PrivateRoute } from '@stur/containers/private-route';

import { CompleteAccountPage } from './complete-account';
import { DashboardPage } from './dashboard';
import { EventDetailPage } from './event-detail';

export const AppPage: FC = () => {
  return (
    <Router basepath={process.env.APP_PATH} className="app" primary={false}>
      <PrivateRoute path="/complete-account" component={CompleteAccountPage} />
      <PrivateRoute path="/" component={DashboardPage} default={true} />

      <EventDetailPage path="/event/:eventId" />
    </Router>
  );
};
