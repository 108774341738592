interface ImageSize {
  width: number;
  height: number;
}

/**
 * Preload an image in order to measure its dimensions
 *
 * @throws UIEvent if image load is aborted
 * @throws ErrorEvent if image fails to load
 * @returns image dimensions
 */
async function getImageSize(uri: string): Promise<ImageSize> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => {
      resolve({ width: image.naturalWidth, height: image.naturalHeight });
    });
    image.addEventListener('abort', (event) => reject(event));
    image.addEventListener('error', (event) => reject(event));
    image.src = uri;
  });
}

/**
 * Construct a cloudinary CDN url for a given media path
 * Media path may contain '{0}' placeholder where cloudinary settings
 * for image format, size, etc. are inserted.
 */
function getCloudImage(mediaUrl: string, width?: number, height?: number): string {
  // if the URL is absolute, do not alter
  if (/^https?:\/\//.test(mediaUrl)) {
    return mediaUrl;
  }

  // remove leading slash from relative path
  if (mediaUrl.charAt(0) === '/') {
    mediaUrl = mediaUrl.slice(1);
  }

  // cloudinary processing params
  const params: string[] = ['q_auto', 'f_auto'];
  if (width || height) {
    if (width) {
      params.push(`w_${width}`);
    }

    params.push(height ? `h_${height}` : 'h_auto');
  }

  return `${process.env.IMAGE_BASE}/${mediaUrl.replace('{0}', params.join(','))}`;
}

export const ImageUtils = {
  getImageSize,
  getCloudImage,
} as const;
