import { Form, Formik } from 'formik';
import React, { VFC } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { ActivityIndicator } from '@stur/components/core/activity-indicator';
import { Button } from '@stur/components/core/button';
import { FormError } from '@stur/components/core/form-error';
import { FormField } from '@stur/components/core/form-field';
import { ServerValidationErrorMapping } from '@stur/errors/server-validation-error';
import { useConstant } from '@stur/hooks/use-constant';
import { AuthSelectors } from '@stur/store/auth/auth-selectors';

import styles from './complete-account-form.module.scss';

export interface CompleteAccountFormProps {
  isLoading: boolean;
  errors?: Array<ServerValidationErrorMapping<CompleteAccountFormValue>>;
  onSubmit: (formValue: CompleteAccountFormValue) => void;
}

export interface CompleteAccountFormValue {
  firstName: string;
  lastName: string;
  username: string;
}

const defaultValues: CompleteAccountFormValue = {
  firstName: '',
  lastName: '',
  username: '',
};

const validationSchema: Yup.SchemaOf<CompleteAccountFormValue> = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  username: Yup.string().required('Username is required'),
});

export const CompleteAccountForm: VFC<CompleteAccountFormProps> = (props) => {
  const { isLoading, errors, onSubmit } = props;
  const authUser = useSelector(AuthSelectors.getAuthUser);
  const initialValues = useConstant<CompleteAccountFormValue>(() => ({
    firstName: authUser?.firstName || defaultValues.firstName,
    lastName: authUser?.lastName || defaultValues.lastName,
    username: defaultValues.username,
  }));

  const handleSubmit = (values: CompleteAccountFormValue): void => onSubmit(values);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form className="complete-account-form" noValidate>
        <label>
          <span>First Name</span>
          <FormField<CompleteAccountFormValue>
            type="text"
            name="firstName"
            autoComplete="given-name"
            placeholder="First Name"
          />
        </label>
        <FormError<CompleteAccountFormValue> name="firstName" serverErrors={errors} />

        <label>
          <span>Last Name</span>
          <FormField<CompleteAccountFormValue>
            type="text"
            name="lastName"
            autoComplete="family-name"
            placeholder="Last Name"
          />
        </label>
        <FormError<CompleteAccountFormValue> name="lastName" serverErrors={errors} />

        <label>
          <span>Username</span>
          <FormField<CompleteAccountFormValue>
            type="text"
            name="username"
            autoComplete="off"
            placeholder="Username"
          />
        </label>
        <FormError<CompleteAccountFormValue> name="username" serverErrors={errors} />

        <div className={styles.formActions}>
          <Button type="submit" color="primary" className="expanded" disabled={isLoading}>
            <ActivityIndicator isLoading={isLoading}>Submit</ActivityIndicator>
          </Button>
        </div>
      </Form>
    </Formik>
  );
};
