import React, { VFC } from 'react';

import { EventLocationModel } from '@stur/models/event-location-model';
import { PollOption } from '@stur/models/poll-model';

export interface EventLocationProps {
  eventLocation: EventLocationModel | (EventLocationModel & PollOption);
}

export const EventLocation: VFC<EventLocationProps> = (props) => {
  const { eventLocation } = props;

  const getLabel = () => {
    if (eventLocation.name || eventLocation.address) {
      return [eventLocation.name, eventLocation.address].filter((part) => !!part).join(', ');
    }
    if (eventLocation.location) {
      return [eventLocation.location.latitude, eventLocation.location.longitude].join(',');
    }

    console.warn('Unable to find label for location');
    return 'Location';
  };

  const getLink = () => {
    const base = 'https://www.google.com/maps/search/?api=1&';

    const parameters: string[] = [];

    if (eventLocation.placeID) {
      parameters.push(`query_place_id=${encodeURIComponent(eventLocation.placeID)}`);
    }

    let queryParts: string[] = [];

    if (eventLocation.name) {
      queryParts.push(encodeURIComponent(eventLocation.name));
    }

    if (eventLocation.address) {
      const addressParts = eventLocation.address
        .split(/[\s,]+/)
        .map((part) => encodeURIComponent(part));
      queryParts = queryParts.concat(addressParts);
    }

    if (eventLocation.location?.latitude && eventLocation.location?.longitude) {
      const lat = encodeURIComponent(eventLocation.location.latitude);
      const lng = encodeURIComponent(eventLocation.location.latitude);
      queryParts.push(`${lat},${lng}`);
    }

    if (queryParts.length > 0) {
      const query = queryParts.join('+');
      parameters.push(`query=${query}`);
    }

    return base + parameters.join('&');
  };

  if ((eventLocation as PollOption).pollOptionKind === 'none') {
    return <>None of these work</>;
  }
  if ((eventLocation as PollOption).pollOptionKind === 'nopreference') {
    return <>No preference</>;
  }

  return (
    <a className="text-truncate v-align-middle" href={getLink()} target="_blank" rel="noreferrer">
      {getLabel()}
    </a>
  );
};
