import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FriendUserModel } from '@stur/models/friend-user-model';
import { UserActions } from '@stur/store/user/user-reducer';
import { UserSelectors } from '@stur/store/user/user-selector';

/**
 * Fetches updated user data whenever the array of user Ids is updated
 * Returns a map of uid -> FriendUserModel for easy lookups during render
 * Note that the user data will be unavailable while loading and users will be loaded one at a time
 */
export function useUserCache(userIds?: string[]): Record<string, FriendUserModel> {
  const dispatch = useDispatch();
  const userCache = useSelector(UserSelectors.getUserCache);
  const prevUserIdsRef = useRef(new Set<string>());

  useEffect(() => {
    if (userIds?.length) {
      const doFetch = userIds.some((id) => !prevUserIdsRef.current.has(id));
      if (doFetch) {
        dispatch(UserActions.cacheUsers({ uids: userIds }));
      }

      prevUserIdsRef.current = new Set(userIds);
    }
  }, [userIds, dispatch]);

  return userCache;
}
