import React, {
  FC,
  useState,
  useRef,
  useLayoutEffect,
  HTMLAttributes,
  DetailedHTMLProps,
} from 'react';

import { AnimationDuration, AnimationEase } from '@stur/constants/animation.constant';

type Phase = 'close' | 'closing' | 'closed' | 'open' | 'opening' | 'opened';

type ExpandStatus = Extends<Phase, 'open' | 'close'>;

const phaseGroup: Record<Phase, ExpandStatus> = {
  close: 'close',
  closed: 'close',
  opening: 'close',
  closing: 'open',
  open: 'open',
  opened: 'open',
};

const easings: Record<ExpandStatus, AnimationEase> = {
  close: AnimationEase.STANDARD,
  open: AnimationEase.IN_OUT,
};

const durations: Record<ExpandStatus, AnimationDuration> = {
  close: AnimationDuration.LEAVE,
  open: AnimationDuration.ENTER,
};

export interface ExpandProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  isOpen?: boolean;
  tag?: string;
}

export const Expand: FC<ExpandProps> = (props) => {
  const { className, isOpen, children, id, tag = 'div' } = props;
  const [isInitialized, setIsInitialized] = useState(false);
  const [status, setStatus] = useState<Phase>(isOpen ? 'open' : 'close');
  const timeout = useRef<number | NodeJS.Timer | undefined>();
  const refWrapper = useRef<HTMLElement>();
  const duration = durations[phaseGroup[status]];
  const easing = easings[phaseGroup[status]];

  /**
   * When isOpen prop changes, begin the open or close animation
   */
  useLayoutEffect(() => {
    if (isInitialized) {
      if (isOpen) {
        setStatus('opening');
      } else {
        setStatus('closing');
      }
    } else {
      setIsInitialized(true);
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Cycle through states until we reach opn or close
   * opening -> opened -> open
   * closing -> closed -> close
   */
  useLayoutEffect(() => {
    switch (status) {
      case 'opening':
        delay(() => setStatus('opened'), 0);
        break;
      case 'closing':
        delay(() => setStatus('closed'), 0);
        break;
      case 'opened':
        delay(() => setStatus('open'), duration);
        break;
      case 'closed':
        delay(() => setStatus('close'), duration);
        break;
      default:
        break;
    }

    return () => clearDelay();
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDefaultExpandStyle = () => {
    switch (status) {
      case 'opening':
      case 'close':
      case 'closed':
        return { height: 0, overflow: 'hidden' };
      case 'opened':
      case 'closing':
        return { height: refWrapper.current?.scrollHeight, overflow: 'hidden' };
      default:
        return { height: 'auto', overflow: undefined };
    }
  };

  const delay = (fn: () => void, time: number) => {
    clearDelay();
    timeout.current = setTimeout(fn, time);
  };

  const clearDelay = () => {
    if (timeout.current) {
      clearTimeout(timeout.current as number);
      timeout.current = undefined;
    }
  };

  const childProps = {
    id,
    className,
    style: {
      ...getDefaultExpandStyle(),
      transition: `height ${duration}ms ${easing}`,
    },
    ref: refWrapper,
    'aria-hidden': !isOpen,
  };

  return React.createElement(tag, childProps, children);
};
