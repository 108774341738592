import classNames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';

import { ChangeType } from '@stur/models/observed-model';

export interface UpdateIndicatorProps {
  lastUpdate?: number;
  indicatorClass: string;
  className?: string;
  type?: string;
  changeType?: ChangeType;
}

const DURATION = 3000;

export const UpdateIndicator: FC<UpdateIndicatorProps> = (props) => {
  const { className, changeType, children, indicatorClass, lastUpdate, type = 'div' } = props;
  const [showIndicator, setShowIndicator] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const clearTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  useEffect(() => {
    clearTimer();

    if (changeType && lastUpdate && Date.now() - lastUpdate < DURATION) {
      setShowIndicator(true);
      timerRef.current = setTimeout(() => setShowIndicator(false), DURATION);
    }

    return clearTimer;
  }, [lastUpdate, changeType]);

  return React.createElement(
    type,
    { className: classNames(className, { [indicatorClass]: showIndicator }) },
    children
  );
};
