import _throttle from 'lodash/throttle';
import { useState } from 'react';

import { DOMUtils } from '@stur/utils/dom-utils';

import { useEffectOnce } from './use-effect-once';

export interface WindowSize {
  width?: number;
  height?: number;
}

/**
 * Returns the current size of the window, updating on window resize and orientation change
 */
export function useWindowSize(throttle?: number): WindowSize {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffectOnce(() => {
    if (DOMUtils.isSSR()) {
      return;
    }

    // Handler to call on window resize
    const handleResize = _throttle(
      () => {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      },
      throttle,
      { leading: false, trailing: true }
    );

    // Add event listener
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  });

  return windowSize;
}
