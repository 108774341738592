import React, { VFC } from 'react';
import spacetime from 'spacetime';

import { EventTimeModel } from '@stur/models/event-time-model';
import { PollOption } from '@stur/models/poll-model';

export interface EventTimeProps {
  eventTime: EventTimeModel | (EventTimeModel & PollOption);
}

const DATE_FORMAT = '{day-short} {month-short} {date-ordinal}, {year}';
const DATE_TIME_FORMAT = `${DATE_FORMAT} {time}`;

export const EventTime: VFC<EventTimeProps> = (props) => {
  const { eventTime } = props;

  const getLabel = () => {
    if ((eventTime as PollOption).pollOptionKind === 'none') {
      return 'None of these work';
    }
    if ((eventTime as PollOption).pollOptionKind === 'nopreference') {
      return 'No preference';
    }

    const start = eventTime.startDate ? spacetime(eventTime.startDate) : undefined;
    const end = eventTime.endDate ? spacetime(eventTime.endDate) : undefined;

    if (start && end) {
      if (Math.abs(start.endOf('day').diff(end.endOf('day'), 'day')) < 1) {
        return (
          <>
            {start.format(DATE_TIME_FORMAT)} &mdash; {end.format('time')}
          </>
        );
      }

      return (
        <>
          {start.format(DATE_TIME_FORMAT)} &mdash; {end.format(DATE_TIME_FORMAT)}
        </>
      );
    } else if (start) {
      return start.format(DATE_TIME_FORMAT);
    }

    return 'N/A';
  };

  return <>{getLabel()}</>;
};
