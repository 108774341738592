import classNames from 'classnames';
import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

import { Icon, IconName } from '@stur/components/core/icon';

import styles from './icon-item.module.scss';

export interface IconItemProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  iconName: IconName;
}

export const IconItem: FC<IconItemProps> = (props) => {
  const { className, children, iconName, ...rest } = props;

  return (
    <span className={classNames(className, styles.iconItem)} {...rest}>
      <Icon className={styles.icon} name={iconName} />
      <span className={styles.content}>{children}</span>
    </span>
  );
};
