import classNames from 'classnames';
import React, { VFC } from 'react';

import { Badge } from '@stur/components/core/badge';
import { Card } from '@stur/components/core/card';
import { DateIndicator } from '@stur/components/core/date-indicator';
import { EventModel } from '@stur/models/event-model';
import { FriendUserModel } from '@stur/models/friend-user-model';

import { EventImage } from '../event-image';
import { ProfilePicture } from '../profile-picture';
import { UserInfo } from '../user-info';
import styles from './event-tile.module.scss';

export interface EventTileProps {
  className?: string;
  event: EventModel;
  host?: FriendUserModel;
}

export const EventTile: VFC<EventTileProps> = (props) => {
  const { className, event, host } = props;

  const startDate =
    event.whens.length > 0 && event.whens[0]?.startDate
      ? new Date(event.whens[0].startDate)
      : undefined;

  const renderDescription = () => {
    switch (event.eventKind) {
      case 'plan':
        return (
          <Badge
            color="warning"
            icon="making-plans"
            label="Making Plans"
            className={styles.badge}
          />
        );
      case 'event':
        if (event.description) {
          return <h2 className="h1">{event.description}</h2>;
        }
    }
    return null;
  };

  const renderImage = () => {
    switch (event.eventKind) {
      case 'plan':
        return (
          <div className={styles.profilePictureWrap}>
            <ProfilePicture
              photoURL={host?.photoURL}
              profileImage={host?.profileImage}
              favorite={false}
              firstName={host?.firstName}
              lastName={host?.lastName}
              size="full"
            />
          </div>
        );
      case 'event':
        return <EventImage media={event?.media} className={styles.eventImage} />;
    }
  };

  return (
    <div className={classNames(styles.eventTile, className)}>
      <div className={styles.header}>
        <UserInfo
          className={styles.userInfo}
          firstName={host?.firstName}
          lastName={host?.lastName}
          username={host?.username}
          photoURL={host?.photoURL}
          profileImage={host?.profileImage}
          favorite={host?.favorite}
        >
          {renderDescription()}
        </UserInfo>
        {!!startDate && <DateIndicator date={startDate} className={styles.date} />}
      </div>
      <Card className={styles.imageCard} fullFrame={true}>
        <div className={styles.imageWrap}>{renderImage()}</div>
      </Card>
    </div>
  );
};
