import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../root-reducer';
import { UserState } from './user-state';

const getState = (state: RootState): UserState => state.user;

const getUserCache = createSelector(getState, (state) => state.userCache);
const getUser = (uid: string) => createSelector(getUserCache, (userCache) => userCache[uid]);

export const UserSelectors = {
  getUserCache,
  getUser,
} as const;
