import { DependencyList, useLayoutEffect } from 'react';

import { useWindowSize } from './use-window-size';

/**
 * Effect that runs when the window size or one of the dependencies has changed.
 * Also runs if one of the dependencies changes.
 */
export function useOnWindowResize(
  fn: (width: number | undefined, height: number | undefined) => void | (() => void | undefined),
  throttle?: number,
  deps?: DependencyList
): void {
  const { width, height } = useWindowSize(throttle);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => fn(width, height), [width, height].concat(deps));
}
