import classNames from 'classnames';
import React, { VFC } from 'react';

import { MediaModel } from '@stur/models/media-model';
import { ImageUtils } from '@stur/utils/image-utils';

export interface CloudImageProps {
  alt: string;
  className?: string;
  media?: MediaModel | null;
}

export const CloudImage: VFC<CloudImageProps> = (props) => {
  const { alt, className, media } = props;

  if (!media?.url) {
    return null;
  }

  const imageUrl = ImageUtils.getCloudImage(media.url);

  return (
    <img
      className={classNames(className, 'img-fluid')}
      src={imageUrl}
      alt={alt}
      width={media.width}
      height={media.height}
    />
  );
};
