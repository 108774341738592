import React, { VFC } from 'react';

import { LinkButton } from '@stur/components/core/button';
import { Modal } from '@stur/components/core/modal';
import { EventParticipantResponse } from '@stur/models/event-participant-model';
import { RoutingUtils } from '@stur/utils/routing-utils';

export interface AccountModalProps {
  eventId: string;
  response?: EventParticipantResponse;
  pollId?: string;
  votes?: string[];
  show: boolean;
  onClose: () => void;
}

export const AccountModal: VFC<AccountModalProps> = (props) => {
  const { eventId, onClose, response, show, pollId, votes } = props;
  const page = RoutingUtils.routes.eventDetail(eventId, response, pollId, votes);
  const createAccountLink = RoutingUtils.routes.createAccount(page);
  const logInLink = RoutingUtils.routes.logIn(page);

  return (
    <Modal
      title="You need an account"
      footer={
        <>
          <LinkButton color="primary" variant="hollow" to={createAccountLink}>
            Sign Up
          </LinkButton>
          <LinkButton color="secondary" to={logInLink}>
            Log In
          </LinkButton>
        </>
      }
      size="tiny"
      show={show}
      onClose={onClose}
    >
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris nisi ut aliquip ex ea commodo consequat.
      </p>
    </Modal>
  );
};
