import _pick from 'lodash/pick';
import React, { VFC } from 'react';

import { UserInfo } from '@stur/components/common/user-info';
import { UpdateIndicator } from '@stur/components/core/update-indicator';
import { EventParticipantModel } from '@stur/models/event-participant-model';
import { FriendUserModel } from '@stur/models/friend-user-model';
import { ObservedCollectionModel } from '@stur/models/observed-model';

import styles from './event-guest-list.module.scss';

export interface EventGuestListProps {
  participants: ObservedCollectionModel<EventParticipantModel>;
  userCache: Record<string, FriendUserModel>;
}

export const EventGuestList: VFC<EventGuestListProps> = (props) => {
  const { participants, userCache } = props;

  const yesParticipants = participants.filter((p) => p.response === 'yes');
  const noParticipants = participants.filter((p) => p.response === 'no');
  const tbdParticipants = participants.filter((p) => p.response === 'tbd');
  const unknownParticipants = participants.filter((p) => p.response === 'unknown');

  const renderUserList = (list: ObservedCollectionModel<EventParticipantModel>) => {
    if (list.length === 0) {
      return null;
    }

    return (
      <ul className={styles.userList}>
        {list.map((participant) => {
          const user = userCache[participant.uid];
          const userInfoProps = _pick(user, [
            'uid',
            'username',
            'firstName',
            'lastName',
            'photoURL',
            'profileImage',
            'favorite',
          ]);

          return (
            <UpdateIndicator
              type="li"
              key={participant.uid}
              lastUpdate={participant._lastUpdate}
              changeType={participant._changeType}
              indicatorClass={styles.isNew}
            >
              <UserInfo {...userInfoProps} />
            </UpdateIndicator>
          );
        })}
      </ul>
    );
  };

  const renderNameList = (list: ObservedCollectionModel<EventParticipantModel>) => {
    if (list.length === 0) {
      return null;
    }

    return (
      <ul className={styles.nameList}>
        {list.map((participant) => {
          const user = userCache[participant.uid];

          return (
            <li key={participant.uid}>
              {user?.firstName} {user?.lastName}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <ul className={styles.eventGuestList}>
      <li>
        <h4 className={styles.groupHeader}>Yes ({yesParticipants.length})</h4>
        {renderUserList(yesParticipants)}
      </li>

      <li>
        <h4 className={styles.groupHeader}>No ({noParticipants.length})</h4>
        {renderUserList(noParticipants)}
      </li>

      <li>
        <h4 className={styles.groupHeader}>TBD ({tbdParticipants.length})</h4>
        {renderUserList(tbdParticipants)}
      </li>

      <li>
        <h4 className={styles.groupHeader}>Not Yet Responded ({unknownParticipants.length})</h4>
        {renderNameList(unknownParticipants)}
      </li>
    </ul>
  );
};
