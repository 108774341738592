import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';
import _omit from 'lodash/omit';
import React, { VFC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { AuthSelectors } from '@stur/store/auth/auth-selectors';
import { DOMUtils } from '@stur/utils/dom-utils';
import { RoutingUtils } from '@stur/utils/routing-utils';

export interface PrivateRouteProps<T extends RouteComponentProps> extends RouteComponentProps {
  component: VFC<T>;
}

/**
 * Wrapper for all private (client side only) routes in src/containers/app
 */
export const PrivateRoute = <T extends RouteComponentProps>(
  props: PrivateRouteProps<T>
): ReactElement | null => {
  const { component: Component } = props;
  const componentProps = _omit(props, ['component']) as T;
  const isLoggedIn = useSelector(AuthSelectors.isLoggedIn);

  if (!DOMUtils.isClientOnlyPage()) {
    throw new Error('PrivateRoute is not currently supported on top level pages');
  }

  if (DOMUtils.isSSR()) {
    return null;
  }

  // prevent access to this page if the user is not logged in
  if (!isLoggedIn) {
    navigate(RoutingUtils.routes.logIn());
    return null;
  }
  return <Component {...componentProps} />;
};
