import classNames from 'classnames';
import React, { FC } from 'react';

import { ProfilePicture } from '@stur/components/common/profile-picture';
import { MediaModel } from '@stur/models/media-model';
import { StyleUtils } from '@stur/utils/style-utils';

import styles from './user-info.module.scss';

export type UserInfoSize = 'default' | 'large';

export interface UserInfoProps {
  className?: string;
  favorite?: boolean;
  firstName?: string;
  lastName?: string;
  photoURL?: string;
  profileImage?: MediaModel;
  size?: UserInfoSize;
  username?: string;
}

export const UserInfo: FC<UserInfoProps> = (props) => {
  const {
    children,
    className,
    favorite,
    firstName,
    lastName,
    photoURL,
    profileImage,
    size = 'default',
    username,
  } = props;

  const handle = username ? `@${username}` : '';

  return (
    <div
      className={classNames(
        styles.userInfo,
        StyleUtils.modifierClass(styles, 'userInfo', size),
        children ? StyleUtils.modifierClass(styles, 'userInfo', 'withContent') : null,
        className
      )}
    >
      <ProfilePicture
        className={styles.image}
        favorite={favorite}
        firstName={firstName}
        lastName={lastName}
        photoURL={photoURL}
        profileImage={profileImage}
        size="small"
      />
      <div className={styles.details}>
        <div className={styles.name}>
          <div className={styles['name__full']}>
            {firstName} {lastName}
          </div>
          <div className={styles['name__user']}>{handle}</div>
        </div>
        {children}
      </div>
    </div>
  );
};
