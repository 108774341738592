import classNames from 'classnames';
import _isFunction from 'lodash/isFunction';
import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactElement, useState } from 'react';

import { Expand } from '@stur/components/core/expand';
import { Icon } from '@stur/components/core/icon';
import { useConstant } from '@stur/hooks/use-constant';

import styles from './accordion-item.module.scss';

export interface AccordionItemProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  header: ReactElement | string;
  hint?: ReactElement | string | number;
  contentId?: string;
  wrapHeader?: boolean;
}

let currentButtonId = 0;
let currentContentId = 0;

export const AccordionItem: FC<AccordionItemProps> = (props) => {
  const {
    className,
    children,
    contentId,
    disabled,
    header,
    hint,
    id,
    onClick,
    wrapHeader,
    ...rest
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const defaultButtonId = useConstant(() => `accordion-item-button-${currentButtonId++}`);
  const defaultContentId = useConstant(() => `accordion-item-content-${currentContentId++}`);
  const uniqueButtonId = id || defaultButtonId;
  const uniqueContentId = contentId || defaultContentId;

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsExpanded(!isExpanded);

    if (_isFunction(onClick)) {
      onClick(event);
    }
  };

  return (
    <>
      <button
        {...rest}
        id={uniqueButtonId}
        className={classNames('button', styles.accordionItem, className)}
        aria-expanded={isExpanded}
        aria-controls={uniqueContentId}
        disabled={disabled}
        onClick={handleButtonClick}
      >
        <span className={classNames(styles.header, { ['text-truncate']: !wrapHeader })}>
          {header}
        </span>
        {!!hint && <span className={styles.hint}>{hint}</span>}
        <Icon className={styles.icon} name="chevron-down" />
      </button>
      <Expand
        id={uniqueContentId}
        isOpen={isExpanded}
        role="region"
        aria-labelledby={uniqueButtonId}
      >
        <div className={styles.content}>{children}</div>
      </Expand>
    </>
  );
};
