import _isFunction from 'lodash/isFunction';
import React, { useEffect, useState, VFC } from 'react';

import { EventLocation } from '@stur/components/common/event-location';
import { CustomCheckbox } from '@stur/components/core/custom-checkbox';
import { Icon } from '@stur/components/core/icon';
import { IconItem } from '@stur/components/core/icon-item';
import { EventLocationModel } from '@stur/models/event-location-model';
import { EventLocationPollModel } from '@stur/models/event-model';
import { EventPollVotesModel } from '@stur/models/event-poll-votes-model';
import { PollOption } from '@stur/models/poll-model';
import styles from '@stur/styles/modules/poll.module.scss';

import { PollItem } from '../poll-item';

export interface EventLocationListProps {
  allowVote?: boolean;
  allVotes?: EventPollVotesModel;
  className?: string;
  isLoading?: boolean;
  myVotes?: string[];
  onVote?: (selectedTimes: string[]) => void;
  pollOpen?: boolean;
  voterCount?: number;
  wherePoll?: EventLocationPollModel;
  wheres?: EventLocationModel[];
}

export const EventLocationList: VFC<EventLocationListProps> = (props) => {
  const {
    allowVote,
    allVotes,
    className,
    isLoading,
    myVotes = [],
    onVote,
    pollOpen,
    voterCount,
    wherePoll,
    wheres,
  } = props;

  const [lastSelection, setLastSelection] = useState<string>();

  // clear selection on load complete
  useEffect(() => {
    if (!isLoading) {
      setLastSelection(undefined);
    }
  }, [isLoading]);

  const handleCheckboxChange = (id: string, option: EventLocationModel & PollOption) => {
    if (!_isFunction(onVote) || isLoading) {
      return;
    }

    let newVotes: string[];
    const { pollOptionKind } = option;
    const voteIndex = myVotes.indexOf(id);
    if (pollOptionKind === 'general') {
      newVotes = [...myVotes].filter(
        (vote) => wherePoll?.options[vote]?.pollOptionKind === 'general'
      );
      if (voteIndex === -1) {
        newVotes.push(id);
      } else {
        newVotes.splice(voteIndex, 1);
      }
    } else {
      newVotes = voteIndex === -1 ? [id] : [];
    }

    setLastSelection(id);
    onVote(newVotes);
  };

  const renderSingle = () => {
    const eventLocation =
      wheres && wheres.length > 0 ? (wheres[0] as EventLocationModel) : undefined;

    if (!eventLocation) {
      return <>No locations selected</>;
    }

    return (
      <IconItem className={className} iconName="location">
        <EventLocation eventLocation={eventLocation} />
      </IconItem>
    );
  };

  const renderPoll = () => {
    if (!wherePoll) {
      return null;
    }

    const optionIds = Object.keys(wherePoll.options);
    const generalIds = optionIds.filter(
      (id) => wherePoll.options[id]?.pollOptionKind === 'general'
    );
    const generalOptionCount = generalIds.length;
    if (generalOptionCount === 0) {
      return null;
    }
    const noneId = optionIds.find((id) => wherePoll.options[id]?.pollOptionKind === 'none');
    const noPrefId = optionIds.find(
      (id) => wherePoll.options[id]?.pollOptionKind === 'nopreference'
    );

    // sort by name asc, then no preference, then none
    optionIds.sort((a, b) => {
      if (a === noneId) {
        return 2;
      } else if (a === noPrefId) {
        return 1;
      } else if (b === noneId) {
        return -2;
      } else if (b === noPrefId) {
        return -1;
      }
      const aName = (wherePoll.options[a]?.name || '').toLowerCase();
      const bName = (wherePoll.options[b]?.name || '').toLowerCase();
      return aName.localeCompare(bName);
    });

    return (
      <div className={styles.poll}>
        <h3 className={styles.pollHeader}>
          <Icon name="location" className={styles.pollHeaderIcon} />
          <span>{generalOptionCount} Proposed Locations</span>
        </h3>
        {!pollOpen && <div className={styles.pollBanner}>Voting has been closed by the host</div>}
        <ul className={styles.list}>
          {optionIds.map((id) => {
            const option = wherePoll.options[id];

            if (!option) {
              return null;
            }
            return (
              <li key={id}>
                <PollItem
                  className={styles.pollItem}
                  voteCount={(allVotes && allVotes[id]?.length) || 0}
                  voterCount={voterCount ?? 0}
                >
                  <div className={styles.pollItemContent}>
                    <EventLocation eventLocation={option} />
                  </div>
                  {pollOpen && allowVote && (
                    <CustomCheckbox
                      checked={myVotes.includes(id)}
                      isLoading={isLoading && lastSelection === id}
                      labelPosition="left"
                      labelProps={{ className: styles.checkbox }}
                      onChange={() => handleCheckboxChange(id, option)}
                      value={id}
                    />
                  )}
                </PollItem>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  if (wheres && wheres.length > 1) {
    const result = renderPoll();
    if (result) {
      return result;
    }
  }

  return renderSingle();
};
