import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CompleteAccountForm,
  CompleteAccountFormValue,
} from '@stur/components/common/complete-account-form';
import { PageContent } from '@stur/components/layout/page-content';
import { PageTitle } from '@stur/components/layout/page-title';
import { ActionStatusSelectors } from '@stur/store/action-status/action-status-selectors';
import { AuthActions } from '@stur/store/auth/auth-reducer';
import { AuthSelectors } from '@stur/store/auth/auth-selectors';

export const CompleteAccountPage: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(ActionStatusSelectors.isActionPending(AuthActions.completeAccount));
  const errors = useSelector(AuthSelectors.getCompleteAccountErrors);

  const handleSubmit = (values: CompleteAccountFormValue): void => {
    const { firstName, lastName, username } = values;
    dispatch(AuthActions.completeAccount({ firstName, lastName, username }));
  };

  return (
    <>
      <PageTitle header="Complete Account" />
      <PageContent>
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="cell medium-6 large-4">
              <CompleteAccountForm isLoading={isLoading} errors={errors} onSubmit={handleSubmit} />
            </div>
            <div className="cell medium-6 large-8">
              <img src="https://picsum.photos/800/800" alt="About Stur" className="img-fluid" />
            </div>
          </div>
        </div>
      </PageContent>
    </>
  );
};
