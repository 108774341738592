import classNames from 'classnames';
import React, { DetailedHTMLProps, HTMLAttributes, FC } from 'react';

import { ProgressBar } from '@stur/components/core/progress-bar';

import styles from './poll-item.module.scss';

export interface PollItemProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  voteCount: number;
  voterCount: number;
  contentClassName?: string;
}

export const PollItem: FC<PollItemProps> = (props) => {
  const { className, contentClassName, children, voteCount, voterCount, ...rest } = props;

  return (
    <div className={classNames(styles.pollItem, className)} {...rest}>
      <div className={classNames(styles.content, contentClassName)}>
        {children}
        <div className={styles.selects}>
          {voteCount} Vote{voteCount === 1 ? '' : 's'}
        </div>
      </div>
      <ProgressBar progress={voteCount / voterCount} />
    </div>
  );
};
