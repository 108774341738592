import classNames from 'classnames';
import React, { DetailedHTMLProps, FC, InputHTMLAttributes, LabelHTMLAttributes } from 'react';

import { Icon } from '@stur/components/core/icon';
import styles from '@stur/styles/modules/custom-checkbox-radio.module.scss';
import { StyleUtils } from '@stur/utils/style-utils';

import { ActivityIndicator } from '../activity-indicator';

export type CustomCheckboxLabelPosition = 'left' | 'right';

export interface CustomCheckboxProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  isLoading?: boolean;
  labelProps?: DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;
  labelPosition?: CustomCheckboxLabelPosition;
}

export const CustomCheckbox: FC<CustomCheckboxProps> = (props) => {
  const {
    className: inputClassName,
    children,
    checked,
    disabled,
    labelProps,
    labelPosition = 'right',
    isLoading,
    value,
    ...otherInputProps
  } = props;
  const { className: labelClassName, ...otherLabelProps } = labelProps || {};

  // optimistically render checked state while loading
  const showCheckedState = (!!checked && !isLoading) || (!!isLoading && !checked);

  return (
    <label
      className={classNames(
        styles.customCheckbox,
        StyleUtils.modifierClass(styles, 'customCheckbox', labelPosition),
        {
          [styles.disabled]: disabled,
        },
        labelClassName
      )}
      {...otherLabelProps}
    >
      <input
        type="checkbox"
        {...otherInputProps}
        className={inputClassName}
        checked={showCheckedState}
        disabled={disabled || isLoading}
        value={value}
      />
      <span className={styles.button}>
        <ActivityIndicator isLoading={isLoading}>
          <span className={styles.iconWrap}>
            <Icon className={classNames(styles.icon, styles.iconFrame)} name="circle" />
            <Icon className={classNames(styles.icon, styles.iconCheck)} name="check-circle" />
          </span>
        </ActivityIndicator>
        <span className={styles.label}>{children}</span>
      </span>
    </label>
  );
};
