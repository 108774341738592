import React, { DetailedHTMLProps, HTMLAttributes, VFC } from 'react';
import { Except } from 'type-fest';

import styles from './progress-bar.module.scss';

export interface ProgressBarProps
  extends Except<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'children'> {
  progress?: number; // 0-1
}

/**
 * Linear interpolation between two values
 */
function mix(min: number, max: number, val: number) {
  return min * (1 - val) + max * val;
}

export const ProgressBar: VFC<ProgressBarProps> = (props) => {
  const { progress: inputProgress, ...rest } = props;

  const progress = inputProgress ? Math.max(0, Math.min(1, inputProgress)) : 0;
  const width = `${progress ? mix(0, 100, progress) : 0}%`;
  const opacity = progress ? mix(0.25, 1, progress) : 0;

  return (
    <div {...rest}>
      <div className={styles.indicator} style={{ width, opacity }}></div>
    </div>
  );
};
