import classNames from 'classnames';
import React, { DetailedHTMLProps, VFC, HTMLAttributes } from 'react';
import spacetime from 'spacetime';
import { Except } from 'type-fest';

import styles from './date-indicator.module.scss';

export interface DateIndicatorProps
  extends Except<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'children'> {
  date: Date;
}

export const DateIndicator: VFC<DateIndicatorProps> = (props) => {
  const { className, date, ...rest } = props;

  const parsed = spacetime(date);
  if (!parsed.isValid) {
    return null;
  }

  const month = parsed.unixFmt('MMM').slice(0, 3);
  const day = parsed.date();

  return (
    <div className={classNames(styles.dateIndicator, className)} {...rest}>
      <span className={styles.month}>{month}</span>
      <span className={styles.day}>{day}</span>
    </div>
  );
};
