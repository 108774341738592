import classNames from 'classnames';
import React, { VFC } from 'react';

import { Icon } from '@stur/components/core/icon';
import { MediaModel } from '@stur/models/media-model';
import { ImageUtils } from '@stur/utils/image-utils';
import { StyleUtils } from '@stur/utils/style-utils';

import styles from './profile-picture.module.scss';

export type ProfilePictureSize = 'default' | 'small' | 'tiny' | 'large' | 'full';

// must match SASS size variables
export const profilePictureSizes: Record<ProfilePictureSize, number> = {
  default: 60,
  large: 112,
  small: 40,
  tiny: 20,
  full: 240,
} as const;

export interface ProfilePictureProps {
  className?: string;
  favorite?: boolean;
  firstName?: string;
  lastName?: string;
  photoURL?: string;
  profileImage?: MediaModel;
  size?: ProfilePictureSize;
}

export const ProfilePicture: VFC<ProfilePictureProps> = (props) => {
  const {
    className,
    favorite,
    firstName,
    lastName,
    photoURL,
    profileImage,
    size = 'default',
  } = props;

  const firstInitial = firstName?.charAt(0) || '';
  const lastinitial = lastName?.charAt(0) || '';
  const initials = firstInitial + lastinitial;

  const renderImage = () => {
    const profilePhotoUrl = profileImage?.url || photoURL;
    if (profilePhotoUrl) {
      const imageUrl = ImageUtils.getCloudImage(
        profilePhotoUrl,
        profilePictureSizes[size],
        profilePictureSizes[size]
      );
      return <img src={imageUrl} alt={initials} className={styles.image} />;
    }

    return <div className={styles.fallbackText}>{initials}</div>;
  };

  const renderFavorite = () => {
    if (!favorite) {
      return null;
    }

    return (
      <div className={styles.favoriteContainer}>
        <Icon name="star" className={styles.favoriteIcon} />
      </div>
    );
  };

  return (
    <div
      className={classNames(
        className,
        styles.profilePicture,
        StyleUtils.modifierClass(styles, 'profilePicture', size)
      )}
    >
      <div className={styles.imageWrap}>{renderImage()}</div>
      {renderFavorite()}
    </div>
  );
};
