import { RouteComponentProps } from '@reach/router';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EventGuestList } from '@stur/components/common/event-guest-list';
import { EventImage } from '@stur/components/common/event-image';
import { EventLocationList } from '@stur/components/common/event-location-list';
import { EventTimeList } from '@stur/components/common/event-time-list';
import { RsvpSelector } from '@stur/components/common/rsvp-selector';
import { UserInfo } from '@stur/components/common/user-info';
import { AccordionItem } from '@stur/components/core/accordion-item';
import { LinkButton } from '@stur/components/core/button';
import { Card } from '@stur/components/core/card';
import { CopyClipboardButton } from '@stur/components/core/copy-clipboard-button';
import { Icon } from '@stur/components/core/icon';
import { IconItem } from '@stur/components/core/icon-item';
import { OffsetHeightContainer } from '@stur/components/core/offset-height-container';
import { PageContent } from '@stur/components/layout/page-content';
import { PageTitle } from '@stur/components/layout/page-title';
import { AccountModal, AccountModalProps } from '@stur/components/modals/account-modal';
import { NotFoundPage } from '@stur/containers/not-found';
import { useEffectOnce } from '@stur/hooks/use-effect-once';
import { usePageInit } from '@stur/hooks/use-page-init';
import { useUserCache } from '@stur/hooks/use-user-cache';
import { EventParticipantResponse } from '@stur/models/event-participant-model';
import { ActionStatusSelectors } from '@stur/store/action-status/action-status-selectors';
import { AuthSelectors } from '@stur/store/auth/auth-selectors';
import { BrowserSelectors } from '@stur/store/browser/browser-selectors';
import { EventActions } from '@stur/store/event/event-reducer';
import { EventSelectors } from '@stur/store/event/event-selectors';
import { AppDispatch } from '@stur/store/store';
import { RoutingUtils } from '@stur/utils/routing-utils';

import styles from './event-detail-page.module.scss';

export interface EventDetailPageProps extends RouteComponentProps {
  eventId?: string;
}

export const EventDetailPage: FC<EventDetailPageProps> = (props) => {
  const { eventId = '' } = props;
  const dispatch = useDispatch<AppDispatch>();
  const headlineRef = useRef<HTMLHeadingElement>(null);

  const queryParams = RoutingUtils.getQueryParams();
  const pagePath = RoutingUtils.routes.eventDetail(eventId);
  const shareLink = RoutingUtils.getAbsoluteUrl(pagePath);
  const createAccountLink = RoutingUtils.routes.createAccount(pagePath);

  const [respondingValue, setRespondingValue] = useState<EventParticipantResponse | undefined>();
  const didVote = useRef(false);
  const didRsvp = useRef(false);
  const [showAccountModal, setShowAccountModal] = useState<
    Partial<AccountModalProps> | undefined
  >();

  const isMobile = useSelector(BrowserSelectors.breakpointDown('medium'));
  const isResponding = useSelector(ActionStatusSelectors.isActionPending(EventActions.setResponse));
  const isVoting = useSelector(ActionStatusSelectors.isActionPending(EventActions.setPollVotes));
  const isLoggedIn = useSelector(AuthSelectors.isLoggedIn);
  const currentUserId = useSelector(AuthSelectors.getCurrentUserId);
  const {
    event,
    whenPoll,
    whenVoters,
    wherePoll,
    whereVoters,
    myWhenVotes,
    myWhereVotes,
    participants,
    userIds,
    isHost,
    rsvpStatus,
    totalParticipants,
  } = useSelector(EventSelectors.getEventDetailView);
  const show404 = !event || event.eventKind === 'plan';

  // load event on page load
  const isInitialized = usePageInit(() => {
    if (eventId) {
      return [
        dispatch(EventActions.getEvent({ eventId })),
        dispatch(EventActions.getEventPolls({ eventId })),
        dispatch(EventActions.getAccessRequest({ eventId })),
        dispatch(EventActions.observeEventParticipants({ eventId })),
      ];
    }
  }, [eventId, currentUserId]);

  const userCache = useUserCache(userIds);
  const host = event?.host ? userCache[event.host] : null;

  // watch voters on polls
  usePageInit(() => {
    if (isInitialized) {
      const promises = [];
      if (eventId && whenPoll?._id) {
        promises.push(
          dispatch(EventActions.observeEventPollVoters({ eventId, pollId: whenPoll._id }))
        );
      }
      if (eventId && wherePoll?._id) {
        promises.push(
          dispatch(EventActions.observeEventPollVoters({ eventId, pollId: wherePoll._id }))
        );
      }
      return promises;
    }
  }, [isInitialized, whenPoll, wherePoll, eventId]);

  // clear event from store on unload
  useEffectOnce(() => {
    return () => {
      dispatch(EventActions.clearEvent());
    };
  });

  // track page loading
  const isLoadingPage =
    useSelector(
      ActionStatusSelectors.isActionPending([
        EventActions.getEvent,
        EventActions.getEventPolls,
        EventActions.getAccessRequest,
        EventActions.observeEventParticipants,
      ])
    ) || !isInitialized;

  // handle RSVP selection
  const handleRsvp = useCallback(
    (response: EventParticipantResponse) => {
      if (!isLoggedIn) {
        setShowAccountModal({ response });
      } else {
        setRespondingValue(response);
        dispatch(EventActions.setResponse({ response }));
        didRsvp.current = true;
      }
    },
    [dispatch, isLoggedIn]
  );

  // handle voting
  const handleWhenVoteChange = useCallback(
    (votes: string[]) => {
      const pollId = whenPoll?._id;

      if (!isLoggedIn) {
        setShowAccountModal({ pollId, votes });
      } else {
        if (pollId) {
          didVote.current = true;
          dispatch(EventActions.setPollVotes({ pollId, votes }));
        } else {
          throw new Error('Cannot vote, invalid pollId');
        }
      }
    },
    [dispatch, isLoggedIn, whenPoll?._id]
  );
  const handleWhereVoteChange = useCallback(
    (votes: string[]) => {
      const pollId = wherePoll?._id;

      if (!isLoggedIn) {
        setShowAccountModal({ pollId, votes });
      } else {
        if (pollId) {
          didVote.current = true;
          dispatch(EventActions.setPollVotes({ pollId, votes }));
        } else {
          throw new Error('Cannot vote, invalid pollId');
        }
      }
    },
    [dispatch, isLoggedIn, wherePoll?._id]
  );

  // do initial RSVP on event load (if present in query string)
  useEffect(() => {
    if (isLoadingPage || didRsvp.current) {
      return;
    }

    if (queryParams.rsvp) {
      const rsvpValue = queryParams.rsvp.toLowerCase() as EventParticipantResponse;
      handleRsvp(rsvpValue);
    }
  }, [isLoadingPage, queryParams]); // eslint-disable-line react-hooks/exhaustive-deps

  // do initial vote on event load (if present in query string)
  useEffect(() => {
    if (isLoadingPage || didVote.current) {
      return;
    }

    if (queryParams.poll && queryParams.votes) {
      const pollId = queryParams.poll;
      const votes = queryParams.votes.split(',');
      const isWhenPoll = whenPoll && whenPoll._id === pollId;
      const isWherePoll = wherePoll && wherePoll._id === pollId;

      if (isWhenPoll || isWherePoll) {
        // include any previously voted options
        (isWhenPoll ? myWhenVotes : myWhereVotes).forEach((vote) => {
          if (!votes.includes(vote)) {
            votes.push(vote);
          }
        });

        didVote.current = true;
        dispatch(EventActions.setPollVotes({ pollId, votes }));
      }
    }
  }, [isLoadingPage, queryParams]); // eslint-disable-line react-hooks/exhaustive-deps

  // ==========================================================================
  // Render
  //

  const renderPageTitle = () => {
    if (isLoadingPage || show404) {
      return;
    } else if (isHost) {
      return <>You&apos;re hosting this&nbsp;event.</>;
    }

    return <>You&apos;re invited!</>;
  };

  const renderEventTitle = () => {
    if (!event) {
      return;
    }

    return (
      <div className={styles.eventTitle} ref={headlineRef}>
        <UserInfo
          className={styles.userInfo}
          firstName={host?.firstName}
          lastName={host?.lastName}
          username={host?.username}
          photoURL={host?.photoURL}
          profileImage={host?.profileImage}
          favorite={host?.favorite}
        >
          <h1>{event.description}</h1>
        </UserInfo>
      </div>
    );
  };

  const renderAccountCTA = () => {
    if (isLoggedIn) {
      return;
    }

    return (
      <div className={styles.accountCta}>
        <div className={styles.accountCtaMessage}>Want to respond?</div>
        <LinkButton className={styles.accountCtaButton} to={createAccountLink} color="primary">
          Create Your Account
        </LinkButton>
      </div>
    );
  };

  const renderRsvpSection = () => {
    if (isHost || event?.eventKind === 'plan') {
      return;
    }

    const whenOptionCount = event?.whens?.length || 0;
    const whereOptionCount = event?.wheres?.length || 0;
    if (event?.pollOpen || whenOptionCount > 1 || whereOptionCount > 1) {
      return;
    }

    return (
      <div>
        <RsvpSelector
          className={styles.rsvpSelector}
          value={rsvpStatus}
          loadingValue={isResponding ? respondingValue : undefined}
          onRsvp={(response) => handleRsvp(response)}
        />

        {/* <div className={styles.syncCalendarGroup}>
          <label htmlFor="sync-calendar-switch" className={styles.syncLabel}>
            Sync this event to my calendar
          </label>
          <Switch id="sync-calendar-switch" className={styles.syncSwitch} />
        </div> */}
      </div>
    );
  };

  const renderEvent = () => {
    if (!event || show404) {
      return <NotFoundPage />;
    }

    return (
      <div className="grid-x grid-padding-x">
        <div className={classNames('cell large-6 xlarge-8', styles.stickyContainer)}>
          <div className={styles.sticky}>
            {renderEventTitle()}

            {!!isMobile && renderAccountCTA()}

            <Card className={styles.imageCard} fullFrame={true}>
              <EventImage media={event?.media} />
            </Card>

            <CopyClipboardButton
              className={styles.link}
              copyText={shareLink}
              size="small"
              color="secondary-light"
              expanded={true}
            >
              <Icon name="link" size={14} />
              <span>Copy link to this invite</span>
            </CopyClipboardButton>
            <p className={styles.description}>{event.details}</p>
          </div>
        </div>
        <OffsetHeightContainer
          enabled={!isMobile}
          matchHeightRef={headlineRef}
          deps={[event?.description]}
          throttle={33}
          className={classNames('cell large-6 xlarge-4', styles.rightColumnContainer)}
        >
          {!isMobile && renderAccountCTA()}

          <Card className={styles.detailsCard}>
            <EventTimeList
              allowVote={true}
              allVotes={whenVoters}
              isLoading={isVoting}
              myVotes={myWhenVotes}
              onVote={handleWhenVoteChange}
              pollOpen={event.pollOpen}
              voterCount={totalParticipants}
              whenPoll={whenPoll}
              whens={event.whens}
            />

            <EventLocationList
              allowVote={true}
              allVotes={whereVoters}
              isLoading={isVoting}
              myVotes={myWhereVotes}
              onVote={handleWhereVoteChange}
              pollOpen={event.pollOpen}
              voterCount={totalParticipants}
              wherePoll={wherePoll}
              wheres={event.wheres}
            />

            {!!event.additionalLink && (
              <IconItem iconName="link">
                <a
                  className="text-truncate v-align-middle"
                  href={event.additionalLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {event.additionalLink}
                </a>
              </IconItem>
            )}

            {renderRsvpSection()}
          </Card>

          <Card className={styles.guestCard} fullFrame={true}>
            <AccordionItem
              header={
                <>
                  <Icon name="people" /> <span>Who else is on Stur &amp; invited?</span>
                </>
              }
              hint={totalParticipants}
            >
              <EventGuestList participants={participants || []} userCache={userCache} />
            </AccordionItem>
          </Card>
        </OffsetHeightContainer>
      </div>
    );
  };

  return (
    <>
      <PageTitle header={event?.description}>
        <h2 className="h1">{renderPageTitle()}</h2>
      </PageTitle>
      <PageContent isLoading={isLoadingPage}>
        {!isLoadingPage && <div className="grid-container">{renderEvent()}</div>}
      </PageContent>
      <AccountModal
        {...showAccountModal}
        eventId={eventId}
        show={!!showAccountModal}
        onClose={() => setShowAccountModal(undefined)}
      />
    </>
  );
};
