import { RefObject, DependencyList, useState } from 'react';

import { useOnWindowResize } from './use-on-window-resize';

/**
 * Returns the height of a given element, updating whenever the window size changes to
 * account for content wrapping. Also runs if one of the dependencies changes.
 */
export function useMeasureHeight<T extends HTMLElement>(
  ref: RefObject<T>,
  throttle?: number,
  deps?: DependencyList
): number {
  const [height, setHeight] = useState(0);

  useOnWindowResize(
    () => {
      const newHeight = ref.current?.offsetHeight || 0;

      if (newHeight !== height) {
        setHeight(ref.current?.offsetHeight || 0);
      }
    },
    throttle,
    deps
  );

  return height;
}
