import { AsyncThunkAction } from '@reduxjs/toolkit';
import { DependencyList, useEffect, useState } from 'react';

import { AsyncThunkConfig } from '@stur/store/store';

/**
 * Ensures loading actions are properly cancelled when the page is unmounted
 *
 * Returns a boolean that is false on the first render, then true on all
 * subsequent renders. This is useful for ensuring requests have a chance
 * to be set to the pending state before the first render.
 */
export function usePageInit(
  pageLoadActions: () =>
    | Array<ReturnType<AsyncThunkAction<unknown, unknown, AsyncThunkConfig>>>
    | undefined,
  deps?: DependencyList
): boolean {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    setIsInitialized(true);

    const promises = pageLoadActions();

    return () => {
      if (promises) {
        promises.forEach((promise) => promise.abort());
      }
    };
  }, deps || []); // eslint-disable-line react-hooks/exhaustive-deps

  return isInitialized;
}
