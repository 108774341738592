import classNames from 'classnames';
import React, { DetailedHTMLProps, VFC, HTMLAttributes } from 'react';

import { RsvpRadio, RsvpRadioValue } from '@stur/components/common/rsvp-radio';
import { EventParticipantResponse } from '@stur/models/event-participant-model';

import styles from './rsvp-selector.module.scss';

export interface RsvpSelectorProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLFieldSetElement>, HTMLFieldSetElement> {
  disabled?: boolean;
  loadingValue?: EventParticipantResponse;
  onRsvp: (value: EventParticipantResponse) => void;
  value?: EventParticipantResponse;
}

export const RsvpSelector: VFC<RsvpSelectorProps> = (props) => {
  const { className, disabled, loadingValue, onRsvp, value, ...rest } = props;

  const handleRadioChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const response = event.target.value as EventParticipantResponse;
    onRsvp(response);
  };

  return (
    <fieldset className={classNames(className, styles.rsvpSelector)} {...rest}>
      <legend className={styles.legend}>Can you make it?</legend>

      <div className={styles.radioGroup}>
        <RsvpRadio
          labelProps={{ className: styles.radioButton }}
          value="yes"
          name="rsvp"
          checked={value === 'yes'}
          onChange={(event) => handleRadioChanged(event)}
          loadingValue={loadingValue as RsvpRadioValue}
          disabled={disabled}
        />
        <RsvpRadio
          labelProps={{ className: styles.radioButton }}
          value="no"
          name="rsvp"
          checked={value === 'no'}
          onChange={(event) => handleRadioChanged(event)}
          loadingValue={loadingValue as RsvpRadioValue}
          disabled={disabled}
        />
        <RsvpRadio
          labelProps={{ className: styles.radioButton }}
          value="tbd"
          name="rsvp"
          checked={value === 'tbd'}
          onChange={(event) => handleRadioChanged(event)}
          loadingValue={loadingValue as RsvpRadioValue}
          disabled={disabled}
        />
      </div>
    </fieldset>
  );
};
