import classNames from 'classnames';
import React, { DetailedHTMLProps, HTMLAttributes, VFC } from 'react';
import { Except } from 'type-fest';

import { StyleUtils } from '@stur/utils/style-utils';

import { Icon, IconName } from '../icon';
import styles from './badge.module.scss';

export type BadgeColor = 'primary' | 'alert' | 'warning';

export interface BadgeProps
  extends Except<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'children'> {
  color?: BadgeColor;
  icon?: IconName;
  isNew?: boolean;
  label?: string;
}

export const Badge: VFC<BadgeProps> = (props) => {
  const { className, color, icon, isNew, label, ...rest } = props;

  return (
    <div
      className={classNames(
        styles.badge,
        StyleUtils.modifierClass(styles, 'badge', color),
        className
      )}
      {...rest}
    >
      {!!isNew && <span className={styles.newLabel}>New</span>}
      {!!label && <span className={styles.label}>{label}</span>}
      {!!icon && <Icon name={icon} className={styles.icon} />}
    </div>
  );
};
