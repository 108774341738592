import React, {
  CSSProperties,
  DependencyList,
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  RefObject,
} from 'react';

import { useMeasureHeight } from '@stur/hooks/use-measure-height';

export interface OffsetHeightContainerProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  matchHeightRef: RefObject<HTMLElement>;
  throttle?: number;
  deps?: DependencyList;
  enabled?: boolean;
}

/**
 * Container that adds padding to the top equal to the height of the matchHeightRef component.
 * This can help align content across columns.
 *
 * The padding value is updated when the window size changes and the update rate can be throttled.
 */
export const OffsetHeightContainer: FC<OffsetHeightContainerProps> = (props) => {
  const { children, deps, enabled, matchHeightRef, style: initialStyle, throttle, ...rest } = props;
  const headlineHeight = useMeasureHeight(matchHeightRef, throttle, deps);

  const style: CSSProperties = {
    ...initialStyle,
  };

  if (enabled) {
    style.paddingTop = headlineHeight;
  }

  return (
    <div style={style} {...rest}>
      {children}
    </div>
  );
};
