import classNames from 'classnames';
import React, { VFC } from 'react';

import { CloudImage } from '@stur/components/core/cloud-image';
import { MediaModel } from '@stur/models/media-model';

export interface EventImageProps {
  className?: string;
  media?: MediaModel;
}

export const EventImage: VFC<EventImageProps> = (props) => {
  const { className, media } = props;

  if (!media) {
    return (
      <img
        className={classNames('img-fluid', className)}
        src={require('@stur/assets/images/event-default-image.svg')}
        width="332"
        height="225"
        alt="Event banner"
      />
    );
  }

  return <CloudImage media={media} alt="Event banner" className={className} />;
};
