import classNames from 'classnames';
import React, { DetailedHTMLProps, VFC, InputHTMLAttributes, LabelHTMLAttributes } from 'react';
import { Except } from 'type-fest';

import { ActivityIndicator } from '@stur/components/core/activity-indicator';
import { Icon, IconName } from '@stur/components/core/icon';
import { EventParticipantResponse } from '@stur/models/event-participant-model';
import { StyleUtils } from '@stur/utils/style-utils';

import sharedStyles from '../../../styles/modules/custom-checkbox-radio.module.scss';
import styles from './rsvp-radio.module.scss';

export type RsvpRadioValue = Extends<EventParticipantResponse, 'yes' | 'no' | 'tbd'>;

export interface RsvpRadioProps
  extends Except<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'children'
  > {
  loadingValue?: RsvpRadioValue;
  labelProps?: DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;
  value: RsvpRadioValue;
}

export const RsvpRadio: VFC<RsvpRadioProps> = (props) => {
  const {
    className: inputClassName,
    disabled,
    loadingValue,
    labelProps,
    value,
    ...otherInputProps
  } = props;
  const { className: labelClassName, ...otherLabelProps } = labelProps || {};

  const getIconName = (): IconName => {
    switch (value) {
      default:
      case 'tbd':
        return 'tbd';
      case 'yes':
        return 'thumbs-up-outline';
      case 'no':
        return 'thumbs-down-outline';
    }
  };

  return (
    <label
      className={classNames(
        sharedStyles.customRadio,
        styles.rsvpRadio,
        StyleUtils.modifierClass(styles, 'rsvpRadio', value),
        labelClassName,
        { [styles.disabled]: disabled }
      )}
      {...otherLabelProps}
    >
      <input
        type="radio"
        {...otherInputProps}
        className={inputClassName}
        disabled={disabled || !!loadingValue}
        value={value}
      />
      <span className={classNames(sharedStyles.button, styles.button)}>
        <ActivityIndicator isLoading={loadingValue === value}>
          <Icon className={classNames(sharedStyles.icon, styles.icon)} name={getIconName()} />
          <span className={classNames(sharedStyles.label, styles.label)}>
            {value.toUpperCase()}
          </span>
        </ActivityIndicator>
      </span>
    </label>
  );
};
