import { RouteComponentProps } from '@reach/router';
import classNames from 'classnames';
import { Link } from 'gatsby';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EventTile } from '@stur/components/common/event-tile';
import { ActivityIndicator } from '@stur/components/core/activity-indicator';
import { Button } from '@stur/components/core/button';
import { PageContent } from '@stur/components/layout/page-content';
import { PageTitle } from '@stur/components/layout/page-title';
import { usePageInit } from '@stur/hooks/use-page-init';
import { useUserCache } from '@stur/hooks/use-user-cache';
import { EventModel } from '@stur/models/event-model';
import { ActionStatusSelectors } from '@stur/store/action-status/action-status-selectors';
import { EventActions } from '@stur/store/event/event-reducer';
import { EventSelectors } from '@stur/store/event/event-selectors';
import { AppDispatch } from '@stur/store/store';
import { RoutingUtils } from '@stur/utils/routing-utils';

import styles from './dashboard-page.module.scss';

export const DashboardPage: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const eventList = useSelector(EventSelectors.getEventList);
  const eventListUserIds = useSelector(EventSelectors.getEventListUserIds);
  const hasMoreEvents = useSelector(EventSelectors.hasMoreEvents);
  const isLoadingEvents = useSelector(
    ActionStatusSelectors.isActionPending(EventActions.listEvents)
  );
  const userCache = useUserCache(eventListUserIds);

  const isInitialized = usePageInit(() => {
    if (!eventList) {
      return [dispatch(EventActions.listEvents({ append: false }))];
    }
  });
  const isLoadingPage = !isInitialized || (!eventList && isLoadingEvents);

  const handleLoadMoreClick = useCallback(() => {
    dispatch(EventActions.listEvents({ append: true }));
  }, [dispatch]);

  const renderEvent = (event: EventModel) => {
    const link = RoutingUtils.routes.eventDetail(event.id);
    const host = userCache[event.host];
    return (
      <li className={classNames('cell medium-6', styles.eventListItem)} key={event.id}>
        <Link to={link} className={styles.eventListLink}>
          <EventTile event={event} host={host} />
        </Link>
      </li>
    );
  };

  const renderEvents = () => {
    if (!eventList?.length) {
      return 'No Events found';
    }

    return (
      <>
        <ul className={classNames('grid-x grid-padding-x', styles.eventList)}>
          {eventList.map((event) => renderEvent(event))}
        </ul>

        {renderLoadMore()}
      </>
    );
  };

  const renderLoadMore = () => {
    if (!hasMoreEvents) {
      return null;
    }
    return (
      <div className="text-center">
        <Button color="secondary-light" onClick={handleLoadMoreClick}>
          <ActivityIndicator isLoading={isLoadingEvents}>Load More</ActivityIndicator>
        </Button>
      </div>
    );
  };

  return (
    <>
      <PageTitle header="Your plans &amp; invites" />
      <PageContent isLoading={isLoadingPage}>
        {!isLoadingPage && <div className="grid-container">{renderEvents()}</div>}
      </PageContent>
    </>
  );
};
