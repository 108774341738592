import classNames from 'classnames';
import React, { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react';

import styles from './card.module.scss';

export interface CardProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  fullFrame?: boolean;
}

export const Card: FC<CardProps> = (props) => {
  const { children, className, fullFrame, ...rest } = props;

  const renderChild = (child: ReactNode, index: number) => {
    return (
      <div className={styles.cardItem} key={index}>
        <>{child}</>
      </div>
    );
  };

  const renderChildren = () => {
    if (!children) {
      return;
    }

    if (Array.isArray(children)) {
      return children.filter((child) => !!child).map((child, index) => renderChild(child, index));
    }
    return renderChild(children, 0);
  };

  return (
    <div
      className={classNames(styles.card, { [styles.fullFrame]: fullFrame }, className)}
      {...rest}
    >
      {renderChildren()}
    </div>
  );
};
