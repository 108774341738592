import _isFunction from 'lodash/isFunction';
import React, { FC, useState } from 'react';

import { Button, ButtonProps } from '@stur/components/core/button';
import { Icon } from '@stur/components/core/icon';
import { DOMUtils } from '@stur/utils/dom-utils';

import styles from './copy-clipboard-button.module.scss';

const SUCCESS_DURATION = 3000;

type CopyClipboardButtonState = 'default' | 'loading' | 'success';

export interface CopyClipboardButtonProps extends ButtonProps {
  copyText: string;
  onCopySuccess?: () => void;
  onCopyError?: () => void;
}

export const CopyClipboardButton: FC<CopyClipboardButtonProps> = (props) => {
  const { children, copyText, onClick, onCopyError, onCopySuccess, ...rest } = props;
  const [state, setState] = useState<CopyClipboardButtonState>('default');

  /**
   * Attempt to copy text to the clipbaord
   */
  const handleCopyStart = async () => {
    const success = await DOMUtils.copyTextToClipboard(copyText);

    if (success) {
      handleCopySuccess();
    } else {
      handleCopyError();
    }
  };

  /**
   * Handle copy success
   */
  const handleCopySuccess = () => {
    setState('success');
    handleCopyEnd();

    if (_isFunction(onCopySuccess)) {
      onCopySuccess();
    }
  };

  /**
   * Handle copy error
   */
  const handleCopyError = () => {
    setState('default');

    if (_isFunction(onCopyError)) {
      onCopyError();
    }
  };

  /**
   * When copy is complete, display success state briefly
   */
  const handleCopyEnd = () => {
    const timeout = setTimeout(() => setState('default'), SUCCESS_DURATION);

    return () => {
      clearTimeout(timeout);
    };
  };

  /**
   * On click, set state to loading which will initiate the copy
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (_isFunction(onClick)) {
      onClick(event);
    }

    if (state === 'default') {
      setState('loading');
      handleCopyStart();
    }
  };

  const renderStatus = () => {
    switch (state) {
      default:
        return null;

      case 'success':
        return (
          <span className={styles.status}>
            <Icon name="check-circle" />
            <span>Copied!</span>
          </span>
        );
    }
  };

  return (
    <Button onClick={(event) => handleClick(event)} {...rest}>
      {renderStatus()}
      <span className={styles.content}>{children}</span>
    </Button>
  );
};
