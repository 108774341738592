import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import _isFunction from 'lodash/isFunction';
import _isString from 'lodash/isString';
import React, { FC, ReactElement, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';
import { Key } from 'ts-key-enum';

import { AnimationDuration } from '@stur/constants/animation.constant';
import { useConstant } from '@stur/hooks/use-constant';
import { useEffectOnce } from '@stur/hooks/use-effect-once';
import { BrowserSelectors } from '@stur/store/browser/browser-selectors';
import { DOMUtils } from '@stur/utils/dom-utils';

import { Button } from '../button';
import { Icon } from '../icon';
import styles from './modal.module.scss';

export type ModalSize = 'tiny' | 'small' | 'large' | 'full';

export interface ModalProps {
  className?: string;
  size?: ModalSize;
  show?: boolean;
  title?: string | ReactElement;
  footer?: ReactElement;
  onClose?: () => void;
}

let currentId = 0;
let modalRoot: HTMLDivElement;
if (!DOMUtils.isSSR()) {
  modalRoot = document.querySelector('#modal-root') || document.createElement('div');
  modalRoot.id = 'modal-root';
  modalRoot.className = 'modal-root';
  document.body.append(modalRoot);
}

export const Modal: FC<ModalProps> = (props) => {
  const { className, children, footer, onClose, show, size = 'medium', title, ...rest } = props;
  const isMobile = useSelector(BrowserSelectors.breakpointDown('medium'));
  const isFullscreen = isMobile || size === 'full';
  const nextId = useConstant(() => `modal-${currentId++}`);
  const titleId = `${nextId}-title`;
  const container = useConstant(() => {
    if (!modalRoot) {
      return;
    }
    const elem = document.createElement('div');
    elem.id = nextId;
    modalRoot.append(elem);
    return elem;
  });

  useEffectOnce(() => {
    return () => {
      if (container) {
        container.remove();
      }
    };
  });

  useEffect(() => {
    if (!DOMUtils.isSSR() && show) {
      window.addEventListener('keyup', handleKeyPress);
      return () => window.removeEventListener('keyup', handleKeyPress);
    }
  }, [show]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseClick = () => {
    if (_isFunction(onClose)) {
      onClose();
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === Key.Escape) {
      handleCloseClick();
    }
  };

  const renderTitle = () => {
    if (!title) {
      return null;
    }

    if (_isString(title)) {
      return (
        <h2 id={titleId} className={styles.title}>
          {title}
        </h2>
      );
    }
    return (
      <div id={titleId} className={styles.title}>
        {title}
      </div>
    );
  };

  const renderFooter = () => {
    if (!footer) {
      return null;
    }

    return <div className={styles.footer}>{footer}</div>;
  };

  const getTransitionClassNames = (): CSSTransitionClassNames => {
    if (isFullscreen) {
      return {
        enter: styles.transitionFullscreenEnter,
        enterActive: styles.transitionFullscreenEnterActive,
        exit: styles.transitionFullscreenExit,
        exitActive: styles.transitionFullscreenExitActive,
      };
    }

    return {
      enter: styles.transitionWindowEnter,
      enterActive: styles.transitionWindowEnterActive,
      exit: styles.transitionWindowExit,
      exitActive: styles.transitionWindowExitActive,
    };
  };

  const renderModal = () => {
    return (
      <CSSTransition
        in={show}
        timeout={{
          enter: AnimationDuration.ENTER * 0.5 + AnimationDuration.LONG,
          exit: AnimationDuration.LEAVE,
        }}
        classNames={getTransitionClassNames()}
        unmountOnExit
      >
        <div
          className={classNames('reveal-overlay', { full: isFullscreen }, styles.overlay)}
          role="dialog"
          aria-modal={true}
          aria-labelledby={title ? titleId : undefined}
        >
          <Helmet>
            {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
            <html data-reveal-open />
          </Helmet>
          <FocusTrap active={true}>
            <div className={classNames('reveal', size, styles.modal, className)} {...rest}>
              <Button
                className={styles.closeButton}
                variant="clear"
                aria-label="Close modal"
                onClick={handleCloseClick}
              >
                <Icon name="close" />
              </Button>

              {renderTitle()}

              {children}

              {renderFooter()}
            </div>
          </FocusTrap>
        </div>
      </CSSTransition>
    );
  };

  if (!container) {
    return null;
  }

  return createPortal(renderModal(), container);
};
